import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import axios from 'axios'
import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'

loadFonts()

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL ?? 'http://localhost'

if (window.localStorage.getItem('digitalizacion-paciente') != null) {
  const paciente = JSON.parse(window.localStorage.getItem('digitalizacion-paciente'))
  store.commit('SET_PACIENTE', paciente)
}

if (window.localStorage.getItem('digitalizacion-paciente-extranjero') != null) {
  store.commit('SET_EXTRANJERO', true)
}

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(Vue3Tour)
  .mount('#app')
