<template>
  <v-card class="mb-12" elevation="0">
    <viewer :images="images" @inited="inited" class="viewer" ref="viewer">
      <img v-for="(src, i) in images" :src="src" :key="i" class="image" />
    </viewer>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <h3 class=""> NUEVO MEDICAMENTO</h3>
          Ingrese el nombre del medicamento , si no se encuentra en la lista presione <v-icon color="green" size="x-large"
            icon="mdi-plus-circle-outline"></v-icon> .
          <v-row>
            <v-col cols="12" sm="12">
              <v-autocomplete :items="medicamentos" auto-select-first class="flex-full-width my-2"
                v-model="medicamento_seleccionado" v-model:search="search" item-title="descripcion" item-value="id"
                density="comfortable" item-props menu-icon="" placeholder="Ingrese Nombre del Medicamento" variant="solo">
                <template v-slot:append>
                  <v-icon color="green" size="x-large" icon="mdi-plus-circle-outline"
                    @click="agregarMedicamento()"></v-icon>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <h3 class="my-3"> SUBIR RECETA</h3>
              <v-btn :disabled="!validadoRequiereReceta" @click="cambiarVisorCamara()" class="mr-2"
                color="orange-darken-4">
                Foto
                <v-icon end icon="mdi-camera"></v-icon>
              </v-btn>
              <v-btn :disabled="!validadoRequiereReceta" @click="cambiarAdjuntarArchivo()" class="ml-2" color="green">
                Archivo
                <v-icon end icon="mdi-file"></v-icon>
              </v-btn>
              <VisorCamara class="my-4" />
              <v-file-input v-if="adjuntar_archivo" class="my-4" show-size label="Adjuntar Receta" accept="image/*,.pdf"
                v-model="photo" density="compact">
                <template v-slot:append>
                  <v-btn v-if="adjuntar_archivo" color="primary" :disabled="!validadoRequiereReceta" @click="upload()">
                    SUBIR
                  </v-btn>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6">
          <h3 class="my-2"> LISTADO DE MEDICAMENTOS</h3>
          <v-data-table :headers="headers" :items="tablamedicamentos"   density="compact" class="elevation-3">
            <template v-slot:bottom></template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon variant="small" @click="eliminarMedicamento(item)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              Ningun medicamento ha sido agregado
            </template>
            <template v-slot:[`item.pivot.tiene_receta`]="{ item }">
              <v-switch color="success" inset hide-details :model-value="Boolean(item.pivot.tiene_receta)"
                @change="cambiarTieneReceta(item)" variant="small"></v-switch>
            </template>
            <template v-slot:[`item.descripcion`]="{ item }">
              <div v-if="item.reportable == 1">
                <v-badge color="green" floating dot>
                  {{ item.descripcion }}
                </v-badge>
              </div>
              <div v-else>
                {{ item.descripcion }}
              </div>
            </template>
          </v-data-table>
          <div class="mt-4">
            <div class="text-caption">
              <v-badge color="green" dot inline></v-badge>El consumo de este medicamento no impacta en sus
              actividades
              regulares.
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from "v-viewer"
import VisorCamara from './VisorCamara.vue'
export default {
  components: {
    Viewer,
    VisorCamara
  },
  data() {
    return {
      receta: 1,
      images: [],
      foto: null,
      medicamento_string: null,
      switch1: 'SI',
      search: null,
      medicamento_seleccionado: null,
      loading: false,
      headers: [
        {
          title: "Medicamento",
          align: "start",
          sortable: false,
          key: "descripcion",
        },
        { title: "Tiene Receta", value: "pivot.tiene_receta", sortable: false },
        { title: "Acciones", key: "actions", sortable: false },
      ],
      contador_receta: 0,
    }
  },
  watch: {
    search(val) {
      if (val.length > 2) {
        this.loading = true
        const data = {
          valor: val
        };
        this.$store.dispatch('searchMedicamentos', data)
        this.loading = false
        this.medicamento_string = val
      }
    },
    tablamedicamentos: function (newValue) {
      this.contador_receta = 0
      this.validadoRequiereReceta = false
      let me = this;
      newValue.forEach(function (element) {
        if (element.pivot.tiene_receta == 1) {
          me.contador_receta++
        }
      });
      if (me.contador_receta > 0) {
        me.validadoRequiereReceta = true
      }
      if (me.contador_receta == 0 && newValue.length > 0) {
        me.validadoMedicamentos = true
      }
      if (me.contador_receta == 0 && newValue.length == 0) {
        me.validadoMedicamentos = false
      }
      this.tablaMedicamentosTieneReceta = me.contador_receta
      this.tablaMedicamentosTieneTotal = newValue.length
      /* if (me.contador_receta == 0) {
        me.validadoMedicamentos = false
      } */
    },

  },
  computed: {
    medicamentos() {
      return this.$store.state.medicamentosBusqueda;
    },
    medicamentosEvidencia() {
      return this.$store.state.medicamentos;
    },
    tablamedicamentos() {
      return this.$store.state.tablaMedicamentos
    },
    photo: {
      get() { return this.$store.state.photo_medicamento },
      set(val) { this.$store.commit('SET_PHOTO_MEDICAMENTO', val) }
    },
    tablaMedicamentosTieneReceta: {
      get() { return this.$store.state.tablaMedicamentosTieneReceta },
      set(val) { this.$store.commit('SET_TABLA_MEDICAMENTOS_TIENE_RECETA', val) }
    },
    tablaMedicamentosTieneTotal: {
      get() { return this.$store.state.tablaMedicamentosTieneTotal },
      set(val) { this.$store.commit('SET_TABLA_MEDICAMENTOS_TIENE_TOTAL', val) }
    },
    validadoMedicamentos: {
      get() { return this.$store.state.validadoMedicamentos },
      set(val) { this.$store.commit('SET_VALIDADO_MEDICAMENTOS', val) }
    },
    validadoRequiereReceta: {
      get() { return this.$store.state.validadoRequiereReceta },
      set(val) { this.$store.commit('SET_VALIDADO_REQUIERE_RECETA', val) }
    },
    validadoTieneEvidencias() {
      return this.$store.state.validadoTieneEvidencias
    },
    dialog: {
      get() { return this.$store.state.dialog_visor_camara },
      set(val) { this.$store.commit('SET_DIALOG_VISOR_CAMARA', val) }
    },
    adjuntar_archivo: {
      get() { return this.$store.state.adjuntar_archivo },
      set(val) { this.$store.commit('SET_ADJUNTAR_ARCHIVO', val) }
    },
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer
    },

    regresar() {
      this.dialog = false;
    },
    upload() {

      if (this.photo) {
        this.$store.dispatch('uploadEvidenciasMedicamento');
      } else {
        const snackbar = {
          show: true,
          color: 'error',
          message: 'Por favor seleccione un archivo!'
        };
        this.$store.commit('SHOW_SNACKBAR', snackbar);
      }
      this.foto = []
    },
    async agregarMedicamento() {
      const data = {
        medicamento_string: this.medicamento_string,
        id_atencion: this.$route.params.id,
        id_medicamento: this.medicamento_seleccionado
      }
      await this.$store.dispatch('agregarMedicamentoAtencion', data)
      this.$store.dispatch('fetchTablaMedicamentos', this.$route.params.id)
      this.medicamento_seleccionado = null
    },
    async eliminarMedicamento(item) {
      const data = {
        id_medicamento: item.pivot.medicamento_id,
        id_atencion: item.pivot.atencion_medicamento_id
      }
      await this.$store.dispatch('eliminarMedicamento', data)
      await this.$store.dispatch('fetchTablaMedicamentos', data.id_atencion)
    },
    verFotosEvidencia(fotos) {
      this.images = [];
      fotos.forEach(foto => {
        this.images.push(process.env.VUE_APP_API_URL + '/api/showImagen/' + foto.ruta);
      });
      this.$viewer.show();
    },
    async cambiarTieneReceta(val) {
      const data = {
        id_medicamento: val.id,
        id_atencion: val.pivot.atencion_medicamento_id,
        tiene_receta: !val.pivot.tiene_receta
      }
      await this.$store.dispatch('cambiarTieneReceta', data)
      await this.$store.dispatch('fetchTablaMedicamentos', data.id_atencion)
    },
    cambiarVisorCamara() {
      this.dialog = !this.dialog;
    },
    cambiarAdjuntarArchivo() {
      this.adjuntar_archivo = !this.adjuntar_archivo
    },
    convertirABooleano(valor) {
      // Convierte el valor a booleano (true si es 1, false si es 0)
      return valor === 1;
    },

  },
  created() {
    this.$store.dispatch('fetchTablaMedicamentos', this.$route.params.id)
  },
}
</script>