<template>
  <v-card class="mx-auto" elevation="0">
    <v-toolbar dense color="indigo-darken-4" dark>
      <h5 class="mx-auto">DATOS DEL TRABAJADOR</h5>
    </v-toolbar>
    <v-img class="align-end" contain height="250" src="user.jpg">
      <v-card-actions>
        <!-- <v-btn class="mx-2" absolute :disabled="!editar" bottom right dark small color="#EF820F">
          <v-icon dark>
            mdi-cloud-upload
          </v-icon>
        </v-btn> -->
      </v-card-actions>
    </v-img>
    <v-card-text v-if="paciente">
      <v-text-field v-model="paciente.nombres" density="compact" disabled label="NOMBRES" hide-details
        variant="solo"></v-text-field>
      <v-text-field v-model="apellidos" density="compact" disabled label="APELLIDOS" hide-details
        variant="solo"></v-text-field>
      <v-row>
        <v-col>
          <v-text-field v-model="paciente.numero_documento" density="compact" hide-details variant="solo" disabled
            label="DNI"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="paciente.nro_registro" :disabled="!editar" density="compact" hide-details variant="solo"
            label="REGISTRO"></v-text-field>
        </v-col>
      </v-row>
      <v-text-field v-model="paciente.celular" :disabled="!editar" density="compact" hide-details variant="solo"
        label="CELULAR"></v-text-field>
      <v-text-field v-model="paciente.correo" :disabled="!editar" density="compact" hide-details variant="solo"
        label="CORREO"></v-text-field>
      <v-text-field v-model="paciente.puesto" :disabled="!editar" density="compact" hide-details variant="solo"
        label="PUESTO"></v-text-field>
      <v-row>
        <v-col>
          <v-text-field v-model="paciente.edad" density="compact" hide-details variant="solo" disabled
            label="EDAD"></v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="paciente.sexo" density="compact" hide-details variant="solo" disabled
            label="SEXO"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <!--   <v-toolbar dense color="indigo-darken-4" dark>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <h5>CONTACTO DE EMERGENCIA</h5>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar> -->
    <!--    <v-card-text v-if="paciente.contactos_emergencia[0]">
      <v-text-field :disabled="!editar" dense v-model="paciente.contactos_emergencia[0].nombre_completo"
        label="NOMBRES"></v-text-field>
      <v-text-field :disabled="!editar" dense v-model="paciente.contactos_emergencia[0].parentesco"
        label="PARENTESCO"></v-text-field>
      <v-text-field :disabled="!editar" dense v-model="paciente.contactos_emergencia[0].celular"
        label="CELULAR"></v-text-field>
    </v-card-text>
    <v-card-text v-else>
      <v-text-field :disabled="!editar" v-model="contacto_nombres" dense label="NOMBRES"></v-text-field>
      <v-text-field :disabled="!editar" v-model="contacto_parentesco" dense label="PARENTESCO"></v-text-field>
      <v-text-field :disabled="!editar" v-model="contacto_celular" dense label="CELULAR"></v-text-field>
    </v-card-text> -->
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn v-if="!editar" @click="editar = true" variant="outlined" color="orange" dark>
        Editar
      </v-btn>
      <v-btn v-if="editar" @click="guardarPaciente()" variant="outlined" color="green" dark>
        Guardar
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'BarraDerecha',
  data: () => ({
    editar: false,
    contacto_nombres: null,
    contacto_parentesco: null,
    contacto_celular: null,
    nombre_completo: null,
    parentesco: null,
    celular: null,
  }),
  computed: {
    paciente() {
      return this.$store.state.paciente
    },
    apellidos() {
      return this.$store.getters.apellidos
    },
    contacto() {
      return this.$store.state.contacto
    }
  },
  methods: {
    async guardarPaciente() {
    /*   if (this.paciente.contactos_emergencia[0]) {
        this.nombre_completo = this.paciente.contactos_emergencia[0].nombre_completo
        this.parentesco = this.paciente.contactos_emergencia[0].parentesco
        this.celular = this.paciente.contactos_emergencia[0].celular

      }
      else {
        this.nombre_completo = this.contacto_nombres
        this.parentesco = this.contacto_parentesco
        this.celular = this.contacto_celular

      }
      const data = {
        id_paciente: this.paciente.idpacientes,
        nro_registro: this.paciente.nro_registro,
        celular: this.paciente.celular,
        puesto: this.paciente.puesto,
        nombre_contacto: this.nombre_completo,
        parentesco_contacto: this.parentesco,
        celular_contacto: this.celular,
      } */
      await this.$store.dispatch('storeDatosPaciente',this.paciente);
      this.editar = false

    }
  }
}
</script>
  