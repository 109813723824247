<template>
    <div>
      <v-form @submit.prevent="ingresar">
        <p class="ma-4">Ingrese los siguientes datos para acceder al sistema</p>
        <v-text-field v-model.trim="form.numero_documento" :rules="NumeroDocumentoRules" class="mt-4" type="number"
          label="Numero de Documento*"></v-text-field>
        <v-text-field label="Fecha de Nacimiento *" type="date" v-model="form.fecha_nacimiento"
          :rules="FechaNacimientoRules"></v-text-field>
        <p class="text-caption ma-4">(*) Campos obligatorios</p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit">INGRESAR</v-btn>
        </v-card-actions>
      </v-form>
    </div>
  </template>
  <script>
  export default {
    name: "LoginView",
    data() {
      return {
        form: {
          numero_documento: null,
          fecha_nacimiento: null,
        },
        NumeroDocumentoRules: [
          value => {
            if (value) return true
            return 'The fiel Document Number is required'
          },
        ],
        FechaNacimientoRules: [
          value => {
            if (value) return true
            return 'The field Date of Birth is required'
          },
        ],
      }
    },
    methods: {
      ingresar() {
        if (!this.form) return
        this.overlay = true
        this.$store.dispatch('login', this.form)
          .then(() => {
            this.overlay = false
            this.$router.push('/')
          })
          .catch(() => this.overlay = false)
  
      }
    },
    watch: {
      menu(val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },
    computed: {
      overlay: {
        get() { return this.$store.state.overlay },
        set(val) { this.$store.commit('SET_OVERLAY', val) }
      }
    }
  }
  </script>
  
  <style scoped></style>
  