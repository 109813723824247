<template>
    <v-stepper v-model="e1" alt-labels>
        <v-stepper-header>
            <template v-for="n in steps" :key="`${n}-step`">
                <v-stepper-item :complete="e1 > n" :step="`Step {{ n }}`" :value="n.key" color="indigo" :title="n.text">
                </v-stepper-item>
                <v-divider v-if="n.key !== steps.length" :key="n"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-window>
            <v-stepper-window-item :value="1">
                <AgregarMedicamentoStepper />
                <v-card transparent>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn variant="outlined" color="primary" @click="e1 = 2">
                            Siguiente
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-stepper-window-item>
            <v-stepper-window-item :value="2">
                <AgregarRecetaStepper />
                <v-card>
                    <v-card-actions>
                        <v-btn variant="outlined" color="orange" @click="e1 = 1">
                            Anterior
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn variant="outlined" color="primary" @click="e1 = 3">
                            Siguiente
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-stepper-window-item>
            <v-stepper-window-item :value="3">
                <AgregarObservacionesStepper />
                <v-card>
                    <v-card-actions>
                        <v-btn variant="outlined" color="orange" @click="e1 = 2">
                            Anterior
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn variant="outlined" color="green" @click="cerrarDialogMedicamentos()">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-stepper-window-item>
        </v-stepper-window>
    </v-stepper>
</template>
<script>
import AgregarMedicamentoStepper from '@/components/AgregarMedicamentoStepper.vue'
import AgregarObservacionesStepper from '@/components/AgregarObservacionesStepper.vue';
import AgregarRecetaStepper from '@/components/AgregarRecetaStepper.vue';
export default {
    data() {
        return {
            e1: 1,
            step: 1,
            steps: [
                { key: 1, text: 'Medicamento', },
                { key: 2, text: 'Validacion' },
                { key: 3, text: 'Anotaciones' },
            ],
        };
    },
    watch: {
        isUpdating(val) {
            if (val) {
                setTimeout(() => (this.isUpdating = false), 3000);
            }
        },
        validadoTieneEvidencias: function (newValue) {
            console.log(newValue)
            if (newValue == true && this.validadoRequiereReceta == true) this.validadoMedicamentos = true
        },
    },
    computed: {
        dialog() {
            return this.$store.state.dialogAgregarMedicamento;
        },
        validadoMedicamentos: {
            get() { return this.$store.state.validadoMedicamentos },
            set(val) { this.$store.commit('SET_VALIDADO_MEDICAMENTOS', val) }
        },
        validadoTieneEvidencias() {
            return this.$store.state.validadoTieneEvidencias
        },
        validadoRequiereReceta: {
            get() { return this.$store.state.validadoRequiereReceta },
            set(val) { this.$store.commit('SET_VALIDADO_REQUIERE_RECETA', val) }
        },
        tablaMedicamentosTieneTotal: {
            get() { return this.$store.state.tablaMedicamentosTieneTotal },
            set(val) { this.$store.commit('SET_TABLA_MEDICAMENTOS_TIENE_TOTAL', val) }
        },
    },
    methods: {
        cerrarDialogMedicamentos() {
            const data = {
                id_atencion: this.$route.params.id,
                observaciones: this.$store.state.observaciones
            }
            this.$store.dispatch('guardarComentarios',data)
            this.$router.push('/');
            this.$store.commit('SHOW_SUCCESS_SNACKBAR', 'Los medicamentos se guardaron exitosamente,')

            
            this.e1 = 1;
        }
    },
    async mounted() {
        if (this.validadoTieneEvidencias && this.tablaMedicamentosTieneTotal > 0) this.validadoMedicamentos = true
    },
    components: { AgregarMedicamentoStepper, AgregarRecetaStepper, AgregarObservacionesStepper }
}
</script>