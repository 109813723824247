<template>
  <v-card class="mb-12 mt-5" height="200px" elevation="0">
    <p>Coloque aqui cualquier observacion con respecto al medicamento por ejemplo:(El medicamento
      solo lo tomo cuando me duele la cabeza)</p>
    <v-textarea outlined v-model="observaciones"></v-textarea>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
    observaciones: {
      get() { return this.$store.state.observaciones },
      set(val) { this.$store.commit('SET_OBSERVACIONES', val) }
    },
  },
  methods: {
  },
  created() {
    this.$store.dispatch('fetchComentarios', this.$route.params.id)
  }
}
</script>