<template>
  <div>
    <v-form @submit.prevent="ingresar">
      <v-alert type="warning" variant="outlined" class="my-4">
        Declaro que no cuento con los datos para el ingreso regular al portal de fichas digitales, autorizo el uso de la
        información que registraré
        en este formulario con el fin de procesarla.
      </v-alert>
      <v-text-field v-model.trim="form.numero_documento" label="Número de documento *">
      </v-text-field>
      <v-text-field v-model.trim="form.ubigeo" label="Ubigeo de Nacimiento *"></v-text-field>
      <p class="text-caption ma-4">(*) Campos obligatorios</p>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" type="submit">INGRESAR</v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "LoginInvitados",
  data() {
    return {
      form: {
        numero_documento: null,
        ubigeo: null,
      },
      menu: false,
      menu_nacimiento: false,
      conforme: false,
      activePicker: null,
      activePickerNacimiento: null,
      moment,
    }
  },
  methods: {
    ingresar() {
      if (!this.form) return
      this.overlay = true
      this.$store.dispatch('loginInvitados', this.form)
        .then(() => {
          this.overlay = false
          this.$router.push('/')
        })
        .catch(() => this.overlay = false)
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    menu_nacimiento(val) {
      val && setTimeout(() => (this.activePickerNacimiento = 'YEAR'))
    },
  },
  computed: {
    overlay: {
      get() { return this.$store.state.overlay },
      set(val) { this.$store.commit('SET_OVERLAY', val) }
    }
  }
}
</script>

