import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    ubigeoBusqueda: [],
    snackbar: {
      show: false,
      message: null,
      status: null,
    },
    overlay: false,
    paciente: null,
    steps: [
      {
        target: '#v-step-0',  // We're using document.querySelector() under the hood
        content: `Presione este boton para declarar un medicamento`,
        header: {
          title: 'Boton Agregar',
        },
        params: {
          placement: 'top'
        }
      },
      {
        target: '#v-step-1',
        content: 'Una nueva declaración ha sido creada , presionar para agregar medicamentos',
        header: {
          title: 'NUEVA DECLARACIÓN',
        },
        params: {
          placement: 'bottom'
        }
      }
    ],
    dialogAgregarMedicamento: false,
    atencion_medicamento_id: null,
    tablaMedicamentos: [],
    validadoRequiereReceta: false,
    dialog_visor_camara: false,
    adjuntar_archivo: false,
    observaciones: null,
    DialogAdvertenciaCorreo: false
  },
  getters: {
    isAuthenticated(state) {
      return !!state.paciente
    },
    apellidos: state => state.paciente.apellido_paterno + ' ' + state.paciente.apellido_materno,
  },
  mutations: {
    SET_UBIGEO_BUSQUEDA(state, data) {
      state.ubigeoBusqueda = data
    },
    SET_PACIENTE(state, data) {
      state.paciente = data
      state.isAuthenticated = Boolean(data)
    },
    SHOW_SUCCESS_SNACKBAR(state, message) {
      state.snackbar.show = true
      state.snackbar.status = 'success'
      state.snackbar.message = message
    },
    SHOW_ERROR_SNACKBAR(state, message) {
      state.snackbar.color = 'error'
      state.snackbar.message = message
      state.snackbar.show = true
    },
    SET_DATA(state, data) {
      state.data = data
    },
    SET_MEDICAMENTOS(state, data) {
      state.medicamentos = data
    },
    SET_ATENCION_MEDICAMENTO_ID(state, id) {
      state.atencion_medicamento_id = id
    },
    SET_DIALOG_AGREGAR_MEDICAMENTO(state, dialog) {
      state.dialogAgregarMedicamento = dialog
    },
    SET_MEDICAMENTOS_BUSQUEDA(state, data) {
      state.medicamentosBusqueda = data
    },
    SET_TABLA_MEDICAMENTOS(state, data) {
      state.tablaMedicamentos = data
    },
    SET_VALIDADO_MEDICAMENTOS(state, data) {
      state.validadoMedicamentos = data
    },
    SET_VALIDADO_REQUIERE_RECETA(state, data) {
      state.validadoRequiereReceta = data
    },
    SET_TABLA_MEDICAMENTOS_TIENE_RECETA(state, data) {
      state.tablaMedicamentosTieneReceta = data
    },
    SET_TABLA_MEDICAMENTOS_TIENE_TOTAL(state, data) {
      state.tablaMedicamentosTieneTotal = data
    },
    SET_PHOTO_MEDICAMENTO(state, photo) {
      state.photo_medicamento = photo;
    },
    SET_DIALOG_VISOR_CAMARA(state, dialog) {
      state.dialog_visor_camara = dialog
    },
    SET_OBSERVACIONES(state, observaciones) {
      state.observaciones = observaciones
    },
    SET_ADJUNTAR_ARCHIVO(state, adjuntar) {
      state.adjuntar_archivo = adjuntar
    },
    SET_OVERLAY(state, overlay) {
      state.overlay = overlay
    },
    SET_EXTRANJERO(state) {
      state.isForeign = true
    },
    SET_DIALOG_ADVERTENCIA_CORREO(state, dialog) {
      state.DialogAdvertenciaCorreo = dialog
    }
  },
  actions: {
    async searchUbigeos({ commit }, params) {
      try {
        const res = await axios.post(`/api/searchUbigeo`, params)
        commit('SET_UBIGEO_BUSQUEDA', await res.data)
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    async login({ commit }, data) {
      try {
        const res = await axios.post(`/api/loginFechaNacimiento`, data)
        commit('SHOW_SUCCESS_SNACKBAR', await res.data.message)
        commit('SET_PACIENTE', await res.data.data)
        commit('SET_EXTRANJERO', 1)
        window.localStorage.setItem('digitalizacion-paciente', JSON.stringify(res.data.data))
        window.localStorage.setItem('digitalizacion-paciente-extranjero', true)
      } catch (e) {
        commit('SET_PACIENTE', null)
        commit('SHOW_ERROR_SNACKBAR', await e.response.data.message);
        throw new Error(await e.response.data.message)
      }
    },
    async logout({ commit }) {
      window.localStorage.removeItem('digitalizacion-paciente')
      commit('SET_PACIENTE', null)
    },
    async loginInvitados({ commit }, data) {
      try {
        const res = await axios.post(`/api/loginInvitados`, data)
        commit('SHOW_SUCCESS_SNACKBAR', await res.data.message)
        commit('SET_PACIENTE', await res.data.data)
        commit('SET_PACIENTE', await res.data.data)
        window.localStorage.setItem('digitalizacion-paciente', JSON.stringify(res.data.data))
      } catch (e) {
        commit('SET_PACIENTE', null)
        commit('SHOW_ERROR_SNACKBAR', await e.response.data.message);
        throw new Error(await e.response.data.message)
      }
    },
    async loginLugarNacimiento({ commit }, data) {
      try {
        const res = await axios.post(`/api/loginLugarNacimiento`, data)
        //prueba
        commit('SHOW_SUCCESS_SNACKBAR', await res.data.message)
        commit('SET_PACIENTE', await res.data.data)
        window.localStorage.setItem('digitalizacion-paciente', JSON.stringify(res.data.data))
      } catch (e) {
        commit('SET_PACIENTE', null)
        commit('SHOW_ERROR_SNACKBAR', await e.response.data.message);
        throw new Error(await e.response.data.message)
      }
    },
    async fetchAtencion({ commit }, id_paciente) {
      try {
        const res = await axios.get(`/api/fetchAtencion/${id_paciente}`)
        //console.log(res)
        commit('SET_DATA', res.data)
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    async fetchMedicamentos({ commit, state }, id_paciente) {
      try {
        const res = await axios.get(`/api/fetchMedicamento/${id_paciente}`)
        //console.log(res)
        commit('SET_MEDICAMENTOS', res.data)
        const indice = res.data.findIndex((elemento) => elemento.id === state.atencion_medicamento_id);
        if (indice !== -1) {
          console.log(res.data[indice])
          if (res.data[indice].evidencias.length > 0) { commit('SET_VALIDADO_TIENE_EVIDENCIAS', true) }
          else { commit('SET_VALIDADO_TIENE_EVIDENCIAS', false) }
        }
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    async storeMedicamento({ commit }, params) {
      try {
        const res = await axios.post(`/api/storeMedicamento`, params)
        commit('SHOW_SUCCESS_SNACKBAR', await res.data.message)
      } catch (e) {
        commit('SHOW_ERROR_SNACKBAR', await e.response.data.message)
        throw new Error(await e.response.data.message)
      }
    },
    async searchMedicamentos({ commit }, params) {
      try {
        const res = await axios.post(`/api/searchMedicamento`, params)
        console.log(res)
        commit('SET_MEDICAMENTOS_BUSQUEDA', await res.data)
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    async fetchTablaMedicamentos({ dispatch, commit, state }, id_atencion) {
      try {
        const res = await axios.get(`/api/fetchTablaMedicamentos/${id_atencion}`)
        //console.log(res)
        commit('SET_TABLA_MEDICAMENTOS', res.data)
        dispatch('fetchMedicamentos', state.paciente.id)
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    async agregarMedicamentoAtencion(_, params) {
      console.log(params)
      try {
        await axios.post(`/api/storeMedicamentoAtencion`, params)

      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    async cambiarTieneReceta(_, params) {
      try {
        await axios.post(`/api/cambiarTieneReceta`, params)
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    async eliminarMedicamento(_, params) {
      try {
        await axios.post(`/api/eliminarMedicamento`, params)
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    uploadEvidenciasMedicamento({ commit, dispatch, state }) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const snackbar = {};
      const formData = new FormData();
      formData.append("file", state.photo_medicamento[0]);
      formData.append("id_atencion", 8);
      //commit('SET_LOADING', true, { root: true });

      axios.post('/api/evidenciasMedicamento', formData, config)
        .then(res => {
          if (res && res.data) {
            commit('SET_ADJUNTAR_ARCHIVO', false);
            commit('SHOW_SUCCESS_SNACKBAR', 'Evidencia Subida Correctamente');
            dispatch('fetchMedicamentos', state.paciente.id)
          }
        })
        .catch(err => {
          if (err && err.response) {
            snackbar.show = true;
            snackbar.color = "error";
            snackbar.message = err.response.data.message;
            commit('SHOW_SNACKBAR', snackbar, { root: true });
            //dispatch('getEvidencias');
          }
        });
    },
    storeEvidenciaMedicamento({ commit, dispatch, state }, data) {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const snackbar = {};
      const formData = new FormData();
      formData.append("file", new File([data.foto], "snapshot.jpg", { type: "image/jpeg" }));
      formData.append("id_atencion", data.atencion_medicamento_id);
      //commit('SET_LOADING', true, { root: true });

      axios.post('/api/evidenciasMedicamento', formData, config)
        .then(res => {
          if (res && res.data) {
            /*  commit('SET_LOADING', false, { root: true }); */
            snackbar.show = true;
            snackbar.color = "success";
            snackbar.message = "Receta Subida Correctamente";
            /*   commit('SET_DIALOG_EVIDENCIAS_MEDICAMENTO', false);
              commit('SHOW_SNACKBAR', snackbar); */
            dispatch('fetchMedicamentos', state.paciente.id)
          }
        })
        .catch(err => {
          if (err && err.response) {
            snackbar.show = true;
            snackbar.color = "error";
            snackbar.message = err.response.data.message;
            commit('SHOW_SNACKBAR', snackbar, { root: true });
            //dispatch('getEvidencias');
          }
        });
    },
    async guardarComentarios(_, data) {
      try {
        await axios.post(`/api/comentarioAtencionMedicamento`, data)
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    async fetchComentarios({ commit }, id) {
      try {
        const { data } = await axios.get(`api/comentarioAtencionMedicamento/${id}`)
        commit('SET_OBSERVACIONES', data.comentario)
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
    async storeDatosPaciente({ commit }, params) {
      try {
        const res = await axios.post(`/api/storeDatosPaciente`, params)
        commit('SET_PACIENTE', await res.data.paciente)
        /*    commit('SET_CONTACTO_EMERGENCIA', await res.data.paciente.contactos_emergencia[0]) */
        window.localStorage.setItem('digitalizacion-paciente', JSON.stringify(res.data.paciente))
        commit('SHOW_SUCCESS_SNACKBAR', await res.data.message)
      } catch (e) {
        throw new Error(await e.response.data.message)
      }
    },
  },
  modules: {
  }
})
