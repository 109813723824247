<template>
  <v-dialog v-model="DialogAdvertenciaCorreo" width="auto">
    <v-card prepend-icon="mdi-email-remove">
      <template v-slot:title>
        <p class="mx-auto">FALTA CORREO</p>
      </template>
      <v-card-text>
        Para crear una declaracion de medicamentos es necesario el correo , por favor edite su usuario e
        ingrese su correo.
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="DialogAdvertenciaCorreo = false">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'DialogAdvertenciaCorreo',
  data: () => ({
  }),
  computed: {
    DialogAdvertenciaCorreo: {
      get() { return this.$store.state.DialogAdvertenciaCorreo },
      set(val) { this.$store.commit('SET_DIALOG_ADVERTENCIA_CORREO', val) }
    },
  },
  methods: {
  }
}
</script>
  