<template>
  <v-card class="mx-auto " max-width="300" elevation="0">
    <v-toolbar dense color="indigo-darken-4">
      <h5 class="mx-auto">CONTACTOS SOPORTE MEDICO</h5>
    </v-toolbar>
    <v-card-text>
      En caso de dudas contactar al area medica por los siguientes medios:
      <v-row>
        <v-col>
          <v-card class="mt-2">
            <v-card-title>
              TELEFONO
            </v-card-title>
            <v-card-subtitle>
              <p>054-603066</p>
            </v-card-subtitle>
            <v-card-title>
              CORREO
            </v-card-title>
            <v-card-text>
              dl.PE.altas@internationalsos.com
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'BarraDerecha',
  data: () => ({
  }),
  computed: {
  },
  methods: {
  }
}
</script>
  