<template>
  <v-app>
    <v-snackbar transition="scale-transition" top :color="snackbar.status" v-model="snackbar.show" timeout="3500">
      {{ snackbar.message }}
    </v-snackbar>
    <v-app-bar color="indigo-darken-4">
      <v-toolbar-title>ISOS-SALUD</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon class="mx-2" @click="toggleTheme" icon="mdi-theme-light-dark"></v-icon>
      <v-icon class="mx-2" @click="logout" icon="mdi-logout"></v-icon>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer app color="indigo-darken-4" v-if="isAuthenticated">
      &#64;INTERNATIONAL SOS {{ new Date().getFullYear() }}
    </v-footer>
  </v-app>
</template>
<script setup>
import { useTheme } from 'vuetify'

const theme = useTheme()

// Obtener el tema preferido del almacenamiento local al cargar la página
theme.global.name.value = localStorage.getItem('theme') || theme.global.name.value

function toggleTheme() {
  // Cambiar el tema
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'

  // Guardar la preferencia del tema en el almacenamiento local
  localStorage.setItem('theme', theme.global.name.value)
}
</script>
<script >
import { mapState } from 'vuex';
import { mapGetters } from "vuex";
export default {

  name: 'AppContainer',
  data: () => ({
  }),
  computed: {
    ...mapState(['snackbar', 'isLoading']),
    ...mapGetters(['currentUser', 'isAuthenticated'])
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch('logout')
        await this.$router.push('/login')
      } catch (e) {
        this.$store.commit('SHOW_ERROR_SNACKBAR', e.message)
      }
    }
  },
}
</script>
<style scoped></style>