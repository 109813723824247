<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="indigo-darken-4">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>TOMAR FOTO</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                    <camera :resolution="{ width: 1000, height: 1000 }" ref="camera" autoplay></camera>
                </v-card-text>
                <v-card-actions class="mx-auto">
                    <v-spacer></v-spacer>
                    <v-icon @click="snapshot" icon="mdi-camera"></v-icon>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
  
<script>
import Camera from "simple-vue-camera";
export default {
    name: "VisorCamara",
    data() {
        return {
            camera: null
        };
    },
    methods: {
        async snapshot() {
            const blob = await this.$refs.camera.snapshot();
            const data = {
                foto: blob,
                atencion_medicamento_id: this.$route.params.id
            };
            this.$store.dispatch('storeEvidenciaMedicamento', data)
            this.dialog = false
            this.$store.commit('SHOW_SUCCESS_SNACKBAR', 'Foto subida correctamente')
            /* const response = await axios.post("/api/subirFotoReceta", data);
            console.log("Snapshot sent successfully:", response.data); */
        },
    },
    components: {
        Camera,
    },
    created() {
    },
    computed: {
        dialog: {
            get() { return this.$store.state.dialog_visor_camara },
            set(val) { this.$store.commit('SET_DIALOG_VISOR_CAMARA', val) }
        },
    },

};
</script>
