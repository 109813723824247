import { createRouter, createWebHashHistory } from 'vue-router'
import store from "../store";
import LoginUbigeoLocal from "@/views/LoginUbigeoLocal";
import LoginFechaNacimiento from "@/views/LoginFechaNacimiento";
import LoginInvitados from "@/views/LoginInvitados";
import LoginView from "@/views/LoginView";
import AppContainer from "@/AppContainer";
import FichasView from '@/views/FichasView';
import NuevaDeclaracionMedicamento from '@/views/NuevaDeclaracionMedicamento'



const routes = [
  {
    path: '/login',
    component: LoginView,
    children: [
      { path: '', component: LoginUbigeoLocal, name: 'login.peruanos' },
      { path: 'extranjeros', component: LoginFechaNacimiento, name: 'login.extranjeros' },
      { path: 'invitados', component: LoginInvitados, name: 'login.invitados' },
    ]
  },
  {
    path: '/',
    component: AppContainer,
    children: [
      { path: '', component: FichasView, name: 'fichas.index' },
      { path: 'declaracion_medicamento/:id', component: NuevaDeclaracionMedicamento, name: 'declaracion_medicamento.crear' },
      /*{ path: 'editar/:id_ficha', component: NuevaFicha, name: 'fichas.editar' } */
    ],
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next('/login')
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
