<template>
  <!--   TODO mejorar codigo -->
  <v-card flat tile>
    <viewer :images="images" @inited="inited" class="viewer" ref="viewer">
      <img v-for="(src, i) in images" :src="src" :key="i" class="image" />
    </viewer>
    <v-toolbar dense color="indigo-darken-4" dark>
      <h5 class="mx-auto">DECLARACIÓN DE MEDICAMENTOS</h5>
      <v-icon id="v-step-0" class="mx-2" size="x-large" @click="nuevaAtencion()" icon="mdi-plus-circle"
        color="orange"></v-icon>
    </v-toolbar>
    <v-card-text>
      <v-data-table :headers="headers_medicamentos" :items="medicamentos" hide-default-footer class="elevation-1">
        <template v-slot:bottom></template>
        <template v-slot:[`item.estado.descripcion`]="{ item }">
          <v-chip v-if="item.estado.id == 2" size="small" color="green">
            {{ item.estado.descripcion }}
          </v-chip>
          <v-chip v-else size="small" color="orange">
            {{ item.estado.descripcion }}
          </v-chip>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ moment(item.created_at).format('DD-MM-YYYY hh:mm A') }}
        </template>
        <template v-slot:[`item.medicamentos`]="{ item }">
          <v-icon id="v-step-1" v-if="item.estado.id == 2" disabled icon="mdi-medical-cotton-swab"
            color="blue-grey-darken-2"></v-icon>
          <v-icon id="v-step-1" v-else @click="abrirDialogAgregarMedicamento(item.id)" icon="mdi-medical-cotton-swab"
            color="indigo"></v-icon>
        </template>
      </v-data-table>
      <DialogAdvertenciaCorreo />
    </v-card-text>
  </v-card>
</template>
<script>
import moment from 'moment'
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from "v-viewer"
import DialogAdvertenciaCorreo from './DialogAdvertenciaCorreo.vue'
export default {
  name: 'DeclaracionMedicamentos',
  components: {
    Viewer,
    DialogAdvertenciaCorreo
  },
  data: () => ({
    moment,
    images: [],
    fab: false,
    headers_medicamentos: [
      { title: 'Fecha', align: 'center', sortable: false, value: 'created_at' },
      { title: 'Medicamento', align: 'center', value: 'medicamentos' },
      { title: 'Estado', align: 'center', value: 'estado.descripcion' },
    ],
  }),
  computed: {
    medicamentos() {
      return this.$store.state.medicamentos
    },
    steps() {
      return this.$store.state.steps
    }
  },

  methods: {
    async nuevaAtencion() {
      const data = {
        id_paciente: this.$store.state.paciente.id
      }
      await this.$store.dispatch('storeMedicamento', data)
      await this.$store.dispatch('fetchMedicamentos', this.$store.state.paciente.id)
      this.$tours['myTour'].nextStep();
    },
    inited(viewer) {
      this.$viewer = viewer
    },
    abrirDialogEvidenciasMedicamento(id) {
      this.$store.commit('SET_ATENCION_MEDICAMENTO_ID', id)
      this.$store.commit('SET_DIALOG_EVIDENCIAS_MEDICAMENTO', true)
    },
    verFotosEvidencia(fotos) {
      this.images = [];
      fotos.forEach(foto => {
        this.images.push(process.env.VUE_APP_API_URL + '/api/showImagen/' + foto.ruta);
      });
      this.$viewer.show();
    },
    abrirDialogAgregarMedicamento(id) {
      this.$tours['myTour'].skip()
      if (this.$store.state.paciente.correo == '' || this.$store.state.paciente.correo == null) { this.$store.commit('SET_DIALOG_ADVERTENCIA_CORREO', true) }
      else { this.$router.push(`/declaracion_medicamento/${id}`); }
    }
  },
  mounted() {

  }
}
</script>
  