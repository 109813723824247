<template>
    <v-col cols="12" offset-lg="2" offset-md="1" lg="8" md="10" sm="12" xs="12">
        <v-overlay :model-value="overlay" class="align-center justify-center">
            <v-progress-circular color="indigo-darken-4" indeterminate size="70" :width="10"></v-progress-circular>
        </v-overlay>
        <v-card outlined>
            <p class="text-h4 mt-5 text-center">ISOS SALUD</p>
            <v-tabs>
                <v-tab exact to="/login">Peruanos</v-tab>
                <v-tab exact to="/login/extranjeros">Extranjeros</v-tab>
                <v-tab exact to="/login/invitados">Invitados</v-tab>
            </v-tabs>
            <router-view></router-view>
        </v-card>
    </v-col>
</template>
  
<script>

export default {
    name: "LoginView",
    data: () => ({
        //
    }),
    computed: {
        overlay: {
            get() { return this.$store.state.overlay },
            set(val) { this.$store.commit('SET_OVERLAY', val) }
        }
    },
}
</script>
  
<style scoped></style>